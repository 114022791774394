import { Box, Button, CircularProgress, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ActiveServiceObject from "./ActiveServiceObject";
import { baseURL } from "../../../api";

const ActiveService = () => {
    const [serviceBooking, setServiceBooking] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedId, setSelectedId] = useState([])
    const history = useHistory();

    const token = localStorage.getItem("authToken");
    useEffect(() => {
        const fetchServiceData = async () => {
            try {
                const response = await axios.get(`${baseURL}booking/get_booking_list/?page=1`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setServiceBooking(response?.data?.data)
                setLoading(false);
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
        fetchServiceData()
    }, [])
    const openDetailPage = (id) => {
        console.log("clicked", id);
        const selectedItem = serviceBooking.results.find(item => item.id === id);

        history.push({
            pathname: `/mooner/details/active_service/${id}`,
            state: { selectedItem }
        });
    }
    return (
        <TableContainer style={{ overflowX: 'hidden' }}>
            <Typography>Active Service Bookings</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Job Id</TableCell>
                        <TableCell>Seeker</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>DateTime</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (

                        <TableRow>
                            <TableCell colSpan={7} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <CircularProgress />
                            </TableCell>
                        </TableRow>
                    ) : serviceBooking?.results?.length > 0 ? (

                        serviceBooking?.results?.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.seeker}</TableCell>
                                <TableCell>{item.category_name}</TableCell>
                                <TableCell>{moment(item.created_at).format("MMMM Do YYYY")}</TableCell>
                                <TableCell>{moment(item.created_at).format("HH:mm:ss")}</TableCell>
                                <TableCell>{item.budget}</TableCell>
                                <TableCell>{item.order_status}</TableCell>
                                <TableCell>
                                    <Box>
                                        <Button size="small" style={{ textTransform: "capitalize" }} onClick={() => openDetailPage(item.id)} >View</Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (

                        <TableRow>
                            <TableCell colSpan={7} style={{ textAlign: 'center' }}>
                                <Typography>No records found</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

        </TableContainer>
    )
}
export default ActiveService;